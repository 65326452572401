<script setup>
import ClipboardJS from "clipboard";
import { onMounted, ref, defineProps, onUnmounted, defineEmits } from "vue";
import { uniqueId } from "lodash-es";
import store from "@/store";

const props = defineProps({
  value: String,
});
const emit = defineEmits(["cancel"]);
const target = uniqueId("target-");
const button = uniqueId("button-");

const input = ref(null);
const clippy = ref(null);
onMounted(() => {
  clippy.value = new ClipboardJS(`.${button}`);

  clippy.value.on("success", function (e) {
    store.dispatch("toasts/add", { message: "Link copied!", variant: "success", timeout: 1000 });
    e.clearSelection();
  });
});

onUnmounted(() => {
  clippy.value.destroy();
});
const cancel = () => {
  emit("cancel");
};
</script>

<template>
  <div v-if="props.value">
    <div class="mb-2 w-full">
      <input ref="input" type="text" :value="props.value" readonly="readonly" :id="target" class="border-gray-400 border p-4 rounded w-full" />
    </div>
    <div class="flex">
      <button class="btn btn-sm btn-green mr-2" :class="[button]" :data-clipboard-target="`#${target}`">Copy to Clipboard</button>
      <button class="btn btn-sm btn-ch1red" @click="cancel">Cancel</button>
    </div>
  </div>
</template>
