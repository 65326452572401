<script setup>
import { onMounted, ref, defineProps } from "vue";
import store from "@/store";
import router from "@/router";
import TermsAcceptModal from "./TermsAcceptModal.vue";

const props = defineProps({
  redirect: {
    type: Boolean,
    default: true,
  },
  from: String,
});

const loading = ref(true);

onMounted(async () => {
  loading.value = true;
  let attempt;
  try {
    attempt = await store.dispatch("auth/getUser");
  } catch (e) {
    // router.push({ name: "Home" });
    console.info("Could not get user - you aren't logged in");
  }

  if (!attempt && props.redirect) {
    router.push({
      name: "Login",
      query: {
        from: props.from ? encodeURI(props.from) : null,
      },
    });
  }

  loading.value = false;
});
</script>

<template>
  <div class="flex-grow w-full" v-if="loading">
    <div class="container">
      <div class="py-24 text-center">Loading user info...</div>
    </div>
  </div>
  <div class="flex-grow w-full h-full" v-else>
    <slot></slot>
  </div>
  <TermsAcceptModal />
</template>

<style></style>
